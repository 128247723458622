<template>
  <!--
    Used to edit the CutConfig-2-Installation property of the installation-Config-List-items.
  -->
  <div class="installationConfigListItemEditCutConfig2InstallationId">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="everythingIsAvailable">
        <form>
          <div
            v-if="cutConfig2InstallationList"
            class="form-group"
          >
            <table class="defaultTable m-0">
              <colgroup>
                <col width="33%">
                <col width="33%">
                <col width="33%">
              </colgroup>
              <thead>
                <tr>
                  <th class="align-top">
                    {{ $t('name') }}
                  </th>
                  <th class="align-top">
                    {{ $t('installationConfigListItemEditCutConfig2InstallationId.sortId') }}
                  </th>
                  <th class="align-top">
                    {{ $t('options') }}
                  </th>
                </tr>
              </thead>
            </table>
            <table class="defaultTable m-0 addCutConfig2InstallationIdTable">
              <colgroup>
                <col width="32.5%">
                <col width="33%">
                <col width="33.5%">
              </colgroup>
              <tbody>
                <tr>
                  <td
                    colspan="2"
                    class="border-bottom-0"
                  >
                    <select
                      v-model="cutConfigId"
                      :class="['form-control form-control-sm', { 'is-invalid': this.$validator.errors.has('CutConfigId') }]"
                    >
                      <option :value="null">
                        {{ $t('pleaseSelectOne') }}
                      </option>
                      <option
                        v-for="(cutConfig, index) in computedCutConfigList"
                        :key="`optionCutConfigId${ index }`"
                        :value="cutConfig.id"
                      >
                        <template v-if="cutConfig.name">
                          {{ cutConfig.name }}
                        </template>  
                        <template v-else>
                          ?
                        </template>
                      </option>
                    </select>
                    <span
                      v-show="errors.has('CutConfigId')"
                      class="badge badge-danger"
                    >{{ errors.first('CutConfigId') }}</span>
                  </td>
                  <td class="border-bottom-0">
                    <button
                      class="btn btn-primary btn-block btn-sm"
                      :disabled="cutConfigId == null"
                      @click.prevent="addCutConfig2InstallationId()"
                    >
                      <font-awesome-icon
                        class="mr-2"
                        icon="plus"
                      />
                      <span>{{ $t('add') }}</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="defaultTable">
              <colgroup>
                <col width="33%">
                <col width="33%">
                <col width="33%">
              </colgroup>
              <Container
                :get-child-payload="getChildPayload"
                tag="tbody"
                @drop="onDrop"
              >
                <Draggable
                  v-for="(cutConfig2Installation, index) in cutConfig2InstallationList"
                  :key="`installationConfigListItemEditCutConfig2InstallationId_Draggable${ index }`"
                  tag="tr"
                >
                  <td>
                    <template v-if="cutConfig2Installation.cutConfigId">
                      {{ getCutConfigById(cutConfig2Installation.cutConfigId).name }}
                    </template>  
                    <template v-else>
                      unknown name
                    </template>
                  </td>
                  <td>{{ cutConfig2Installation.sortId }}</td>
                  <td>
                    <button
                      class="btn btn-secondary btn-block btn-sm"
                      @click.prevent="openConfirmRemoveModal(cutConfig2Installation)"
                    >
                      <font-awesome-icon
                        class="mr-2 gray"
                        icon="trash"
                      />
                      <span>{{ $t('remove') }}</span>
                    </button>
                  </td>
                </Draggable>
              </Container>
            </table>
          </div>
        </form>
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>

    <SweetModal
      ref="confirmDelete"
      :title="$t('installationConfigListItemEditCutConfig2InstallationId.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p v-if="currentCutConfig">
        {{ $t('installationConfigListItemEditCutConfig2InstallationId.sureToDelete', { cutConfig: currentCutConfig.name }) }}
      </p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="times"
        />
        <span>{{ $t('cancel') }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="deleteCutConfig2InstallationId()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />
        <span>{{ $t('delete') }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue';
import { Container, Draggable } from "vue-smooth-dnd";
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "InstallationConfigListItemEditCutConfig2InstallationId",
  components: {
    Container,
    Draggable,
    SweetModal
  },
  mixins: [
    errorMixin
  ],
  props: {
    installationConfigId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      cutConfig2InstallationList: null,
      cutConfigList: null,
      cutConfigId: null,
      installationList: null,
      installationConfig: null,
      currentCutConfig2Installation: null,
      currentCutConfig: null
    }
  },
  computed: {
    computedCutConfigList () {
      let idArray = this.cutConfig2InstallationList.map(element => element.cutConfigId);
      return this.cutConfigList.filter(cutConfig => idArray.includes(cutConfig.id) == false);
    },
    everythingIsAvailable: function () {
      if (!this.installationConfig) {
        return false;
      }
      if (!this.cutConfig2InstallationList) {
        return false;
      }
      if (!this.cutConfigList) {
        return false;
      }
      return true;
    }
  },
  created () {
    this.getData();
  },
  methods: {
    saveSortOrder () {
      let sortOrderArray = this.cutConfig2InstallationList.map(a => {
        let tmpObj = {
          'SortId': a.sortId,
          'CutConfigId': a.cutConfigId,
          'InstallationConfigId': a.installationConfigId
        }
        return tmpObj;
      });
      return this.axios.post(`/VideoPostprocessing/SaveSortOrder`, sortOrderArray)
        .then(() => {
        })
        .catch(() => {
          this.$snotify.error(this.$t('installationConfigListItemEditCutConfig2InstallationId.wasNotSaved'));
        });
    },
    sortCutConfig2InstallationId () {
      this.cutConfig2InstallationList.sort((a, b) => {
        return a.sortId - b.sortId;
      });
    },
    onDrop (dropResult) {
      let movedElement = this.cutConfig2InstallationList.find((element) => {
        return element.sortId == dropResult.removedIndex;
      });
      let moveDown = dropResult.addedIndex > dropResult.removedIndex;
      this.cutConfig2InstallationList.map((element) => {
        if (moveDown && element.sortId <= dropResult.addedIndex && element.sortId > dropResult.removedIndex) {
          element.sortId--;
        }
        if (!moveDown && element.sortId >= dropResult.addedIndex && element.sortId < dropResult.removedIndex) {
          element.sortId++;
        }
      });
      movedElement.sortId = dropResult.addedIndex;
      this.sortCutConfig2InstallationId();
      this.saveSortOrder();
    },
    getChildPayload (index) {
      return this.cutConfig2InstallationList[index];
    },
    getCutConfigById (id) {
      if (!id) {
        return null;
      }
      let cutConfig = this.cutConfigList.find(cutConfig => cutConfig.id === id);
      if (cutConfig) {
        return cutConfig;
      }

      return null;
    },
    openConfirmRemoveModal (cutConfig2Installation) {
      this.currentCutConfig2Installation = cutConfig2Installation;
      this.currentCutConfig = this.getCutConfigById(cutConfig2Installation.cutConfigId);
      this.$refs.confirmDelete.open();
    },
    deleteCutConfig2InstallationId () {
      this.axios.delete(`/VideoPostprocessing/DeleteCutConfig2InstallationConfig?cutConfigId=${ this.currentCutConfig2Installation.cutConfigId }&installationConfigId=${ this.currentCutConfig2Installation.installationConfigId }`)
        .then(() => {
          this.$snotify.success(this.$t('installationConfigListItemEditCutConfig2InstallationId.successfullyRemoved'));
        })
        .finally(() => {
          let i = this.cutConfig2InstallationList.length;
          while (i--) {
            if (this.cutConfig2InstallationList[i].sortId == this.currentCutConfig2Installation.sortId) {
              this.cutConfig2InstallationList.splice(i, 1);
            }
          }

          this.cutConfig2InstallationList.map((element) => {
            if (element.sortId > this.currentCutConfig2Installation.sortId) {
              element.sortId--;
            }
          });

          this.sortCutConfig2InstallationId();
          this.saveSortOrder();
          this.currentCutConfig2Installation = null;
          this.$refs.confirmDelete.close();
        });
    },
    getData () {
      this.axios.all([
        this.axios.get(`/VideoPostprocessing/GetAllCutConfig2InstallationIdById?installationConfigId=${ this.installationConfigId }`),
        this.axios.get(`/VideoPostprocessing/GetInstallationConfig?id=${ this.installationConfigId }`),
        this.axios.get('/VideoPostprocessing/GetAllCutConfig'),
        this.axios.get('/VideoPostprocessing/GetIntrooutros'),
        this.axios.get('/Installation/GetAll')
      ]).then(this.axios.spread((cutConfig2InstallationIdResponse, installationConfigResponse, getAllCutConfigResponse, installationsResponse) => {
          this.cutConfig2InstallationList = cutConfig2InstallationIdResponse.data;
          this.installationConfig = installationConfigResponse.data;
          this.cutConfigList = getAllCutConfigResponse.data;
          this.installationList = installationsResponse.data;
          this.cutConfigId = null;
          this.sortCutConfig2InstallationId();
        }))
        .finally(() => {
          this.loading = false;
        });
    },
    refreshCutConfigListData () {
      this.axios.all([
        this.axios.get(`/VideoPostprocessing/GetAllCutConfig2InstallationIdById?installationConfigId=${ this.installationConfigId }`),
      ]).then(this.axios.spread((cutConfig2InstallationIdResponse) => {
          this.cutConfig2InstallationList = cutConfig2InstallationIdResponse.data;
          this.sortCutConfig2InstallationId();
          this.cutConfigId = null;
        }))
        .finally(() => {
          this.loading = false;
        });
    },    
    addCutConfig2InstallationId () {
      let cutConfig2InstallationTmp = {
        'InstallationConfigId': this.installationConfigId,
        'CutConfigId': this.cutConfigId,
        'SortId': this.cutConfig2InstallationList.length
      };
      this.axios.post(`/VideoPostprocessing/AddCutConfig2InstallationId`, cutConfig2InstallationTmp)
        .then(() => {
          this.error_clear();
          this.$snotify.success(this.$t('installationConfigListItemEditCutConfig2InstallationId.successfullyAdded'));
          this.refreshCutConfigListData();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style scoped>
.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
  display: table-row;
  cursor: grab;
}
.addCutConfig2InstallationIdTable {
  background: #eee;
}
</style>
